<template>
    <div>
        <ayl-berad-nav :nav="nav"></ayl-berad-nav>
        <ayl-map-multiple-polygon v-model="mapData"></ayl-map-multiple-polygon>
        <div class="real-main m18px">
            <div class="real-main-tree">
                <div class="real-main-tree-tabs">
                    <div class="tabs-item" :class="leftTitle === item.tabsName ? 'tabs-click' : ''"
                        v-for="(item, index) in tabsType" :key="index" @click="clickIsShow(item.tabsName)"><i
                            class="iconfont" :class="item.icon"></i> {{ item.tabsName }}</div>
                </div>
                <div class="real-main-tree-item" v-if="isShow">
                    <div class="tree-item-title">
                        <span class="item-title">{{ leftTitle }}</span>
                        <span class="item-close" @click="clickClose(leftTitle)"><i class="el-icon-close"></i></span>
                    </div>
                    <div class="tree-item-list" v-show="leftTitle === '标段列表'">
                        <el-tree :data="getBidNameAndIdList" :props="defaultProps" node-key="id" show-checkbox
                            :accordion="true" check-strictly @check="checkGroupNode" :default-expand-all="true"
                            ref="tree"></el-tree>
                    </div>
                    <div class="tree-item-list" v-show="leftTitle === '统计信息'">
                        <div class="list-item" style="border: none">
                            <span class="statistics-icon"><i class="el-icon-s-custom"></i></span>
                            <span class="statistics-text">人员在线信息</span>
                        </div>
                        <!-- 人员在线信息 -->
                        <el-collapse accordion>
                            <!-- 总人数 -->
                            <el-collapse-item>
                                <template slot="title">
                                    <div style="color:rgba(126,129,135,1);margin-left: 10px;width: 100%;margin-right: -20px;" @click="handleCollapseChange(0)">
                                        总人数({{ getStatisticsData.totalPersonCount }})</div>
                                </template>
                                <el-form :inline="true" :model="form" class="demo-form-inline">
                                    <el-form-item>
                                        <el-input v-model="form.queryName" prefix-icon="el-icon-search"
                                            placeholder="请输入姓名"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onPersonnelSubmit(0)">查询</el-button>
                                    </el-form-item>
                                    <div class="business" v-for="(item, index) in statisticalPersonnelData" :key="index">
                                        <div class="business-icon">
                                            <i class="el-icon-s-custom icon" v-if="item.personImageUrlLink === null"></i>
                                            <img :src="item.personImageUrlLink" alt="" style="width: 36px;height: 36px;border-radius: 50%;" v-else>
                                        </div>
                                        <div class="business-test">
                                            <div class="test-name">
                                                <span class="name" style="padding: 0 5px;">{{ item.name }}</span>
                                                <el-tooltip class="item" effect="dark" :content="item.sexDes"
                                                    placement="top-end">
                                                    <i class="iconfont"
                                                        :class="item.sexDes === '男' ? 'iconxingbie-nan' : 'iconxingbie-nv'"
                                                        :style="item.sexDes === '男' ? 'color: #409EFF' : 'color: #F56C6C'"
                                                        style="padding: 0 5px; "></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" :content="item.personTypeDes"
                                                    placement="top">
                                                    <i class="iconfont iconrenyuanzaigang1" style="padding: 0 5px;"
                                                        :style="item.personTypeDes ==='正常在岗' ? 'color: #1ABC9C' : item.personTypeDes ==='异常在岗' ? 'color: #FF8C1A' : 'color: #AAADB3'"></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="实时位置"
                                                    placement="top-start">
                                                    <i class="iconfont iconshishiweizhi" style="padding: 0 5px;"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">联系电话：</span>
                                                <span class="text">{{ item.phone }}</span>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">所属企业：</span>
                                                <span class="text">{{ item.companyName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-collapse-item>
                            <!-- 排班人数 -->
                            <el-collapse-item>
                                <template slot="title">
                                    <div style="color:rgba(126,129,135,1);margin-left: 10px;width: 100%;margin-right: -20px;" @click="handleCollapseChange(1)">
                                        排班人数({{ getStatisticsData.shiftPersonCount }})</div>
                                </template>
                                <el-form :inline="true" :model="form" class="demo-form-inline">
                                    <el-form-item>
                                        <el-input v-model="form.queryName" prefix-icon="el-icon-search"
                                            placeholder="请输入姓名"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onPersonnelSubmit(1)">查询</el-button>
                                    </el-form-item>
                                    <div class="business" v-for="(item, index) in statisticalPersonnelData" :key="index">
                                        <div class="business-icon">
                                            <i class="el-icon-s-custom icon" v-if="item.personImageUrlLink === null "></i>
                                            <img :src="item.personImageUrlLink" alt="" style="width: 36px;height: 36px;border-radius: 50%;" v-else>
                                        </div>
                                        <div class="business-test">
                                            <div class="test-name">
                                                <span class="name" style="padding: 0 5px;">{{ item.name }}</span>
                                                <el-tooltip class="item" effect="dark" :content="item.sexDes"
                                                    placement="top-end">
                                                    <i class="iconfont"
                                                        :class="item.sexDes === '男' ? 'iconxingbie-nan' : 'iconxingbie-nv'"
                                                        :style="item.sexDes === '男' ? 'color: #409EFF' : 'color: #F56C6C'"
                                                        style="padding: 0 5px; "></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" :content="item.personTypeDes"
                                                    placement="top">
                                                    <i class="iconfont iconrenyuanzaigang1" style="padding: 0 5px;"
                                                        :style="item.personTypeDes ==='正常在岗' ? 'color: #1ABC9C' : item.personTypeDes ==='异常在岗' ? 'color: #FF8C1A' : 'color: #AAADB3'"></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="实时位置"
                                                    placement="top-start">
                                                    <i class="iconfont iconshishiweizhi" style="padding: 0 5px;"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">联系电话：</span>
                                                <span class="text">{{ item.phone }}</span>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">所属企业：</span>
                                                <span class="text">{{ item.companyName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-collapse-item>
                            <!-- 在岗人数 -->
                            <el-collapse-item>
                                <template slot="title">
                                    <div style="color:rgba(126,129,135,1);margin-left: 10px;width: 100%;margin-right: -20px;" @click="handleCollapseChange(2)">
                                        在岗人数({{ getStatisticsData.shiftLinePersonCount }})</div>
                                </template>
                                <el-form :inline="true" :model="form" class="demo-form-inline">
                                    <el-form-item>
                                        <el-input v-model="form.queryName" prefix-icon="el-icon-search"
                                            placeholder="请输入姓名"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onPersonnelSubmit(2)">查询</el-button>
                                    </el-form-item>
                                    <div class="business" v-for="(item, index) in statisticalPersonnelData" :key="index">
                                        <div class="business-icon">
                                            <i class="el-icon-s-custom icon" v-if="item.personImageUrlLink === null"></i>
                                            <img :src="item.personImageUrlLink" alt="" style="width: 36px;height: 36px;border-radius: 50%;" v-else>
                                        </div>
                                        <div class="business-test">
                                            <div class="test-name">
                                                <span class="name" style="padding: 0 5px;">{{ item.name }}</span>
                                                <el-tooltip class="item" effect="dark" :content="item.sexDes"
                                                    placement="top-end">
                                                    <i class="iconfont"
                                                        :class="item.sexDes === '男' ? 'iconxingbie-nan' : 'iconxingbie-nv'"
                                                        :style="item.sexDes === '男' ? 'color: #409EFF' : 'color: #F56C6C'"
                                                        style="padding: 0 5px; "></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" :content="item.personTypeDes"
                                                    placement="top">
                                                    <i class="iconfont iconrenyuanzaigang1" style="padding: 0 5px;"
                                                        :style="item.personTypeDes ==='正常在岗' ? 'color: #1ABC9C' : item.personTypeDes ==='异常在岗' ? 'color: #FF8C1A' : 'color: #AAADB3'"></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="实时位置"
                                                    placement="top-start">
                                                    <i class="iconfont iconshishiweizhi" style="padding: 0 5px;"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">联系电话：</span>
                                                <span class="text">{{ item.phone }}</span>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">所属企业：</span>
                                                <span class="text">{{ item.companyName }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-collapse-item>
                        </el-collapse>

                        <!-- 车辆在线信息 -->
                        <div class="list-item" style="border: none">
                            <span class="statistics-icon"><i class="iconfont iconcheliangzuoye3" style="font-size: 14px;"></i></span>
                            <span class="statistics-text">车辆在线信息</span>
                        </div>
                        <el-collapse accordion>
                            <!-- 车辆总数 -->
                            <el-collapse-item>
                                <template slot="title">
                                    <div style="color:rgba(126,129,135,1);margin-left: 10px;width: 100%;margin-right: -20px;" @click="handleCarChange(0)">
                                        车辆总数({{ getStatisticsData.totalCarCount }})</div>
                                </template>
                                <el-form :inline="true" :model="form" class="demo-form-inline">
                                    <el-form-item>
                                        <el-input v-model="form.queryCar" prefix-icon="el-icon-search"
                                            placeholder="请输入车牌号"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onCarSubmit(0)">查询</el-button>
                                    </el-form-item>
                                    <div class="business" v-for="(item, index) in statisticalCarData" :key="index">
                                        <div class="business-icon">
                                            <i class="iconfont iconcheliangzuoye3 icon" v-if="item.carImageUrl === null"></i>
                                            <img :src="item.carImageUrl" alt="" style="width: 36px;height: 36px;border-radius: 50%;" v-else>
                                        </div>
                                        <div class="business-test">
                                            <div class="test-name">
                                                <span class="name"
                                                    style="padding: 0 5px;">{{ item.plateNumber }}</span>
                                                <el-tooltip class="item" effect="dark" :content="item.status"
                                                    placement="top">
                                                    <i class="iconfont iconcheliangzuoye3" style="padding: 0 5px;"
                                                        :style="item.status ==='有效作业中' ? 'color: #1ABC9C' : item.status ==='非作业中' ? 'color: #FF8C1A' : item.status ==='报警' ? 'color: #F0090A' : 'color: #AAADB3'"></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="实时位置"
                                                    placement="top-start">
                                                    <i class="iconfont iconshishiweizhi" style="padding: 0 5px;"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">车辆类型：</span>
                                                <span class="text">{{ item.carTypeDes }}</span>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">所属企业：</span>
                                                <span class="text">{{ item.company }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-collapse-item>
                            <!-- 排班车辆数 -->
                            <el-collapse-item>
                                <template slot="title">
                                    <div style="color:rgba(126,129,135,1);margin-left: 10px;width: 100%;margin-right: -20px;" @click="handleCarChange(1)">
                                        排班车辆数({{ getStatisticsData.routeCarCount }})</div>
                                </template>
                                <el-form :inline="true" :model="form" class="demo-form-inline">
                                    <el-form-item>
                                        <el-input v-model="form.queryCar" prefix-icon="el-icon-search"
                                            placeholder="请输入车牌号"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onCarSubmit(1)">查询</el-button>
                                    </el-form-item>
                                    <div class="business" v-for="(item, index) in statisticalCarData" :key="index">
                                        <div class="business-icon">
                                            <i class="iconfont iconcheliangzuoye3 icon" v-if="item.carImageUrl === null"></i>
                                            <img :src="item.carImageUrl" alt="" style="width: 36px;height: 36px;border-radius: 50%;" v-else>
                                        </div>
                                        <div class="business-test">
                                            <div class="test-name">
                                                <span class="name"
                                                    style="padding: 0 5px;">{{ item.plateNumber }}</span>
                                                <el-tooltip class="item" effect="dark" :content="item.status"
                                                    placement="top">
                                                    <i class="iconfont iconcheliangzuoye3" style="padding: 0 5px;"
                                                        :style="item.status ==='有效作业中' ? 'color: #1ABC9C' : item.status ==='非作业中' ? 'color: #FF8C1A' : item.status ==='报警' ? 'color: #F0090A' : 'color: #AAADB3'"></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="实时位置"
                                                    placement="top-start">
                                                    <i class="iconfont iconshishiweizhi" style="padding: 0 5px;"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">车辆类型：</span>
                                                <span class="text">{{ item.carTypeDes }}</span>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">所属企业：</span>
                                                <span class="text">{{ item.company }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-collapse-item>
                            <!-- 在线车辆数 -->
                            <el-collapse-item>
                                <template slot="title">
                                    <div style="color:rgba(126,129,135,1);margin-left: 10px;width: 100%;margin-right: -20px;" @click="handleCarChange(2)">
                                        在线车辆数({{ getStatisticsData.onLineCarCount }})</div>
                                </template>
                                <el-form :inline="true" :model="form" class="demo-form-inline">
                                    <el-form-item>
                                        <el-input v-model="form.queryCar" prefix-icon="el-icon-search"
                                            placeholder="请输入车牌号"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onCarSubmit(2)">查询</el-button>
                                    </el-form-item>
                                    <div class="business" v-for="(item, index) in statisticalCarData" :key="index">
                                        <div class="business-icon">
                                            <i class="iconfont iconcheliangzuoye3 icon" v-if="item.carImageUrl === null"></i>
                                            <img :src="item.carImageUrl" alt="" style="width: 36px;height: 36px;border-radius: 50%;" v-else>
                                        </div>
                                        <div class="business-test">
                                            <div class="test-name">
                                                <span class="name"
                                                    style="padding: 0 5px;">{{ item.plateNumber }}</span>
                                                <el-tooltip class="item" effect="dark" :content="item.status"
                                                    placement="top">
                                                    <i class="iconfont iconcheliangzuoye3" style="padding: 0 5px;"
                                                        :style="item.status ==='有效作业中' ? 'color: #1ABC9C' : item.status ==='非作业中' ? 'color: #FF8C1A' : item.status ==='报警' ? 'color: #F0090A' : 'color: #AAADB3'"></i>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="实时位置"
                                                    placement="top-start">
                                                    <i class="iconfont iconshishiweizhi" style="padding: 0 5px;"></i>
                                                </el-tooltip>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">车辆类型：</span>
                                                <span class="text">{{ item.carTypeDes }}</span>
                                            </div>
                                            <div class="test-name">
                                                <span class="text">所属企业：</span>
                                                <span class="text">{{ item.company }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-form>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class="tree-item-list" v-show="leftTitle === '说明'">
                        <div class="list-item" v-for="(item, index) in $enums_hw.instructionsType.list" :key="index">
                            <i class="iconfont" :class="item.icon" :style="'color: '+item.color+';'"
                                style="font-size: 14px;"></i>
                            <span style="padding-left: 10px">{{ item.type }}</span>
                            <span style="color: #2E3033;">{{ item.state }}</span>
                        </div>
                    </div>
                </div>
                
                <!-- 详情页面 -->
                <div class="real-main-tree-item" v-if="isDetails"> 
                    <div class="tree-item-title">
                        <span class="item-title">{{ btnColor }}</span>
                        <span class="item-close" @click="clockLeftDetails(btnColor)"><i class="el-icon-close"></i></span>
                    </div>
                    <!-- 网格 -->
                    <div class="tree-item-list" v-if="btnColor ==='网格'">
                        <div v-if="griddingDetails.sanitationWorkGridVO.gridName !== null">
                            <div class="list-test ml10px mb10px">
                                <span class="label">网格名称：</span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.gridName }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">网格类型：</span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.gridTypeDes }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">负责人：</span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.gridManager }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">联系电话：</span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.contactPhone }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">网格面积：</span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.gridArea || 0}}m²</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">工作时段：</span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.workStartTime + ' ~ ' +  griddingDetails.sanitationWorkGridVO.workEndTime}}</span>
                                <!-- <span>{{ griddingDetails.sanitationWorkGridVO.workStartTime|str2ymdhm }} ~ </span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.workEndTime|str2ymdhm }}</span> -->
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">应排班总人数：</span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.scheduleWorkerNum || 0}}人</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">实际排班总人数：</span>
                                <span>{{ griddingDetails.sanitationWorkGridVO.dutyRealNum || 0}}人</span>
                            </div>
                            <div class="mt20px">
                                <el-table
                                    :data="griddingDetails.sanitationShiftManageVOList"
                                    border
                                    style="width: 100%">
                                    <el-table-column
                                    prop="shiftName"
                                    label="班次名称"
                                    width="70">
                                    </el-table-column>
                                    <el-table-column
                                    label="班次时段"
                                    width="115">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.shiftStartTime + ' ~ ' + scope.row.shiftEndTime || '- -' }}</span>
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="realDutyWorkers"
                                    label="实际排班人数">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                    <!-- 人员 -->
                    <div class="tree-item-list" v-if="btnColor ==='人员'">
                        <div v-if="personnelDetailsData">
                            <div class="business-card mt15px ml10px">
                                <div class="business-card-icon">
                                    <i class="el-icon-s-custom icon" v-if="personnelDetailsData.personImageUrlLink === null"></i>
                                    <img :src="personnelDetailsData.personImageUrlLink" alt="" style="width: 36px; height: 36px; border-radius: 50%;" v-else>
                                </div>
                                <div class="business-card-text">
                                    <span class="name" style="padding: 0 10px;">{{ personnelDetailsData.name }}</span>
                                    <span style="color: #7E8187;" class="ml10px">{{ personnelDetailsData.personTypeDes}}</span>
                                    <div class="ml10px mt10px">
                                        {{ personnelDetailsData.companyName }}
                                    </div>
                                </div>
                            </div>
                            <div class="list-test ml10px mb10px mt20px">
                                <span class="label">联系电话：</span>
                                <span>{{ personnelDetailsData.phone }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">排班网格：</span>
                                <span>{{ personnelDetailsData.gridName }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">最近事件：</span>
                                <span>{{ personnelDetailsData.lastEvent || '- -'}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">上报时间：</span>
                                <span>{{ personnelDetailsData.lastReportTime|str2ymdhm }}</span>
                            </div>
                            <div class="mt20px">
                                <el-table
                                    :data="personnelDetailsData.shiftList"
                                    border
                                    style="width: 100%">
                                    <el-table-column
                                    prop="shiftTime"
                                    label="排班时间"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                    prop="duration"
                                    label="作业时长"
                                    width="75">
                                    </el-table-column>
                                    <el-table-column
                                    prop="mileage"
                                    width="70"
                                    label="作业里程">
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="icon-bottom mt20px ml10px">
                                <el-tooltip class="item" effect="dark" content="实时对讲" placement="bottom">
                                    <span class="icon-bg mr10px">
                                        <i class="iconfont iconduijiangji"></i>
                                    </span>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="实时监听" placement="bottom">
                                    <span class="icon-bg mr10px">
                                        <i class="iconfont iconjianting"></i>
                                    </span>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="TTS语音播报" placement="bottom">
                                    <span style="display: inline-block;vertical-align: middle;margin-right: 10px;">
                                        <img src="../../../assets/images/hw-icon/TTS.png" alt="">
                                    </span>
                                    <!-- <span class="icon-bg mr10px">
                                        <i class="iconfont iconTTS1"></i>
                                    </span> -->
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="轨迹回放" placement="bottom">
                                    <span class="icon-bg mr10px"  @click="onPlayback(personnelDetailsData)">
                                        <i class="iconfont iconlishiguiji"></i>
                                    </span>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <!-- 车辆 -->
                    <div class="tree-item-list" v-if="btnColor ==='车辆'">
                        <div class="business-card mt15px ml10px">
                            <div class="business-card-icon">
                                <i class="iconfont iconcheliangzuoye3 icon" v-if="carDetailsList.carImageUrl === null"></i>
                                <img :src="carDetailsList.carImageUrl" alt="" style="width: 36px; height: 36px; border-radius: 50%;" v-else>
                            </div>
                            <div class="business-card-text">
                                <span class="name pl10px pr10px" style="color:#1ABC9C;text-decoration: underline;">{{ carDetailsList.plateNumber }}</span>
                                <span style="color: #7E8187;" class="ml10px">{{ carDetailsList.carTypeDes }}</span>
                                <div class="ml10px mt10px">
                                    {{ carDetailsList.company }}
                                </div>
                            </div>
                        </div>
                        <div class="list-test ml10px mb10px mt20px">
                            <span class="label">作业路线：</span>
                            <span style="color: #1ABC9C;">{{ carDetailsList.routeName || '- -' }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">车辆状态：</span>
                            <span>{{ carDetailsList.status || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">ACC状态：</span>
                            <span>{{ carDetailsList.accStatus }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">速度：</span>
                            <span>{{ carDetailsList.speed || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">方向：</span>
                            <span>{{ carDetailsList.directionDesc || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">位置：</span>
                            <span>{{ carDetailsList.locationName || '- -' }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">设备号：</span>
                            <span>{{ carDetailsList.deviceName || '- -' }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">设备状态：</span>
                            <span class="mr10px">{{ carDetailsList.deviceStatusDes || '- -' }}</span>
                            <span class="mr10px" style="color: #1ABC9C;">上线</span>
                            <span style="color: #1ABC9C;">设备重启</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">最近事件：</span>
                            <span style="color: #F2090A;">{{ carDetailsList.lastEvent || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">上报时间：</span>
                            <span>{{ carDetailsList.lastReportTime|str2ymdhm }}</span>
                        </div>
                        <div class="icon-bottom mt20px ml5px">
                            <el-tooltip class="item" effect="dark" content="实时监控" placement="bottom">
                                <span class="icon-bg mr10px" @click="onPlayBack(carDetailsList)">
                                    <i class="iconfont iconheader-14"></i>
                                </span>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="实时拍照" placement="bottom">
                                <span class="icon-bg mr10px">
                                    <i class="iconfont iconpaizhao"></i>
                                </span>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="实时对讲" placement="bottom">
                                <span class="icon-bg mr10px">
                                    <i class="iconfont iconduijiangji"></i>
                                </span>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="实时监听" placement="bottom">
                                <span class="icon-bg mr10px">
                                    <i class="iconfont iconjianting"></i>
                                </span>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="TTS语音播报" placement="bottom">
                                <span style="display: inline-block;vertical-align: middle;margin-right: 10px;">
                                    <img src="../../../assets/images/hw-icon/TTS.png" alt="">
                                </span>
                                
                                <!-- <span class="icon-bg mr10px">  
                                    <i class="iconfont iconTTS1"></i>
                                </span> -->
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="轨迹回放" placement="bottom">
                                <span class="icon-bg mr10px" @click="onCarTrajectory(carDetailsList)">
                                    <i class="iconfont iconlishiguiji"></i>
                                </span>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="视频回放" placement="bottom">
                                <span class="icon-bg mr10px" @click="onCarPlayback(carDetailsList)">
                                    <i class="iconfont iconlishishipin"></i>
                                </span>
                            </el-tooltip>
                        </div>
                    </div>
                    <!-- 作业路线 -->
                    <div class="tree-item-list" v-if="btnColor ==='作业路线'">
                        <div class="list-test ml10px mb10px">
                            <span class="label">作业路线名称：</span>
                            <span>{{ jodRouteDetailsData.routeName }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">起止点：</span>
                            <span>{{ jodRouteDetailsData.startPoint }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">保洁等级：</span>
                            <span>{{ jodRouteDetailsData.cleanLevelDes  || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">线长度：</span>
                            <span>{{ jodRouteDetailsData.routeLen  || '- -'}} km</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">道路类型：</span>
                            <span>{{ jodRouteDetailsData.routeTypeDes  || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">主车道数：</span>
                            <span>{{ jodRouteDetailsData.mainLaneNum  || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">辅道数量：</span>
                            <span>{{ jodRouteDetailsData.sideLaneNum  || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">隔离带：</span>
                            <span>{{ hasIsolationType(jodRouteDetailsData.hasIsolation) }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">负责机扫车：</span>
                            <span>{{ jodRouteDetailsData.dutySweeperCar || '- -' }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">机扫车司机：</span>
                            <span>{{ jodRouteDetailsData.dutySweeperDriver || '- -' }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">机扫车司机联系方式：</span>
                            <span>{{ jodRouteDetailsData.dutySweeperDriverPhone || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">机扫要求趟数：</span>
                            <span>{{ jodRouteDetailsData.sweeperTripNum }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">机扫每趟次数：</span>
                            <span>{{ jodRouteDetailsData.sweeperTripTime }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">已机扫趟数：</span>
                            <span>{{ jodRouteDetailsData.doneSweeperTripNum }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">已机扫完成率：</span>
                            <span>{{ jodRouteDetailsData.doneSweeperPercent }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">机扫作业回放：</span>
                            <span class="mr10px" style="color: #1ABC9C;cursor:pointer" @click="onSweeperTrajectory(jodRouteDetailsData)">轨迹回放</span>
                            <span class="ml10px" style="color: #1ABC9C;cursor:pointer" @click="onSweeperPlayback(jodRouteDetailsData)">视频回放</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">负责清洗车：</span>
                            <span>{{ jodRouteDetailsData.dutySprinklerCar || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">清洗车司机：</span>
                            <span>{{ jodRouteDetailsData.dutySprinklerDriver || '- -'}}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">清洗车司机联系方式：</span>
                            <span>{{ jodRouteDetailsData.dutySprinklerDriverPhone || '- -' }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">清洗要求趟数：</span>
                            <span>{{ jodRouteDetailsData.sprinklerTripNum }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">清洗每趟次数：</span>
                            <span>{{ jodRouteDetailsData.sprinklerTripTime }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">已清洗趟数：</span>
                            <span>{{ jodRouteDetailsData.doneSprinklerTripNum }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">已清洗完成率：</span>
                            <span>{{ jodRouteDetailsData.doneSprinklerPercent }}</span>
                        </div>
                        <div class="list-test ml10px mb10px">
                            <span class="label">清洗作业回放：</span>
                            <span class="mr10px" style="color: #1ABC9C;cursor:pointer;" @click="onSprinklerTrajectory(jodRouteDetailsData)">轨迹回放</span>
                            <span class="ml10px" style="color: #1ABC9C;cursor:pointer;" @click="onSprinklerPlayback(jodRouteDetailsData)">视频回放</span>
                        </div>
                    </div>

                    <!-- 其它信息  市政道路 -->
                    <div class="tree-item-list" v-if="btnColor ==='其它信息'">
                        <div v-if="informationType === 0">
                            <div class="list-test ml10px mb10px">
                                <span class="label">名称：</span>
                                <span>{{ otherInfoList.roadName }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">起止位置：</span>
                                <span>{{ otherInfoList.startStopPosition }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">保洁等级：</span>
                                <span>{{ cleanLevelType(otherInfoList.cleanLevel) }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">养护企业：</span>
                                <span>{{ otherInfoList.bidMaintainUnit }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">车道数：</span>
                                <span>{{ otherInfoList.laneNum }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">长度：</span>
                                <span>{{ otherInfoList.mainRoadLen }} m</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">主车道面积：</span>
                                <span>{{ otherInfoList.mainRoadArea }} ㎡</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">辅道：</span>
                                <span>{{ otherInfoList.sideRoadArea }} ㎡</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">门店门前：</span>
                                <span>{{ otherInfoList.storeFrontArea }} ㎡</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">人行道：</span>
                                <span>{{ otherInfoList.sideWalkArea }} ㎡</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">地下通道：</span>
                                <span>{{ otherInfoList.underpassArea }} ㎡</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">水沟面积：</span>
                                <span>{{ otherInfoList.ditchArea }} ㎡</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">是否机扫：</span>
                                <span>{{ otherInfoList.isSweeper=== 0 ? "否" : "是" }}</span>
                            </div>
                        </div>
                        <!-- 城中村 -->
                        <div v-if="informationType === 1 || informationType === 2">
                            <div class="list-test ml10px mb10px">
                                <span class="label">名称：</span>
                                <span>{{ otherInfoList.villageName }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">人口数：</span>
                                <span>{{ otherInfoList.population }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">养护企业：</span>
                                <span>{{ otherInfoList.bidMaintainUnit }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">清扫保洁面积：</span>
                                <span>{{ otherInfoList.cleanArea }} ㎡</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">范围：</span>
                                <span>{{ otherInfoList.villageScope || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">地址：</span>
                                <span>{{ otherInfoList.villageAddress }}</span>
                            </div>
                        </div>
                        <!-- 垃圾中转站 -->
                        <div v-if="informationType === 3">
                            <div class="list-test ml10px mb10px">
                                <span class="label">名称：</span>
                                <span>{{ otherInfoList.transferName }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">养护企业：</span>
                                <span>{{ otherInfoList.bidMaintainUnit }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">厢型：</span>
                                <span>{{ otherInfoList.brand || "- -"}}</span>
                            </div>     
                            <div class="list-test ml10px mb10px">
                                <span class="label">中转站类型：</span>
                                <span>{{ otherInfoList.transferType === 0 ? '固定' : '移动' }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">是否有公厕：</span>
                                <span>{{ otherInfoList.isContainedWc === 0 ? '是' : '否' }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">开放时间：</span>
                                <span>{{ otherInfoList.openStartTime }} ~ {{ otherInfoList.openEndTime }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">地址：</span>
                                <span>{{ otherInfoList.address }}</span>
                            </div>
                        </div>
                        <!-- 垃圾收集点 -->
                        <div v-if="informationType === 4">
                            <div class="list-test ml10px mb10px">
                                <span class="label">名称：</span>
                                <span>{{ otherInfoList.collectName || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">养护企业：</span>
                                <span>{{ otherInfoList.bidMaintainUnit || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">开放时间：</span>
                                <span>{{ otherInfoList.openStartTime }} ~ {{ otherInfoList.openEndTime }}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">清运量：</span>
                                <span>{{ otherInfoList.cleanTonDaily || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">地址：</span>
                                <span>{{ otherInfoList.collectAddress || "- -"}}</span>
                            </div>
                        </div>
                        <!-- 环保垃圾屋 -->
                        <div v-if="informationType === 5">
                            <div class="list-test ml10px mb10px">
                                <span class="label">名称：</span>
                                <span>{{ otherInfoList.ecoName || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">养护企业：</span>
                                <span>{{ otherInfoList.bidMaintainUnit || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">环保垃圾屋类型：</span>
                                <span>{{ otherInfoList.ecoType === 0 ? '固定' : '移动' || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">地址：</span>
                                <span>{{ otherInfoList.ecoAddress || "- -"}}</span>
                            </div>
                        </div>
                        <!-- 公共厕所 -->
                        <div v-if="informationType === 6">
                            <div class="list-test ml10px mb10px">
                                <span class="label">名称：</span>
                                <span>{{ otherInfoList.wcName || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">养护企业：</span>
                                <span>{{ otherInfoList.bidMaintainUnit || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">管理类别：</span>
                                <span>{{ $enums_hw.manageType.manageList(otherInfoList.manageType) || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">管理员：</span>
                                <span>{{ otherInfoList.demandWorkerName || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">男厕便池：</span>
                                <span>{{ otherInfoList.maleStandNum || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">女厕蹲位：</span>
                                <span>{{ otherInfoList.femaleSquatNum || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">水龙头：</span>
                                <span>{{ otherInfoList.faucetNum || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">镜子：</span>
                                <span>{{ otherInfoList.mirrorNum || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">抽风机：</span>
                                <span>{{ otherInfoList.suctionFanNum || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">风扇：</span>
                                <span>{{ otherInfoList.fanNum || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">洗手液：</span>
                                <span>{{ otherInfoList.hasHandSanitizer === 0 ? '无' : '有' || "- -"}}</span>
                            </div>
                            <div class="list-test ml10px mb10px">
                                <span class="label">免费厕纸：</span>
                                <span>{{ otherInfoList.hasFreePaper === 0 ? '无' : '有' || "- -"}}</span>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </div>

            <!-- 右边表格 -->
            <div class="real-main-table" v-show="rightTable === '作业监管表格'">
                <div class="real-main-table-title">
                    <span class="item-title">{{ leafCheckArr.label }}</span>
                    <span class="item-close" @click="clickCloseTable()"><i class="el-icon-close"></i></span>
                </div>
                <div class="real-main-table-tabs">
                    <div class="item-button" v-for="(item, index) in $enums_hw.tabsButtonList.list" :key="index">
                        <el-button :type="btnColor === item.btn ? 'primary' : ''" @click="tabsClick(item.btn)">
                            {{ item.btn }}</el-button>
                    </div>
                    <!-- 网格表格 -->
                    <el-form :inline="true" :model="form" class="demo-form-inline" v-if="btnColor === '网格'">
                        <el-form-item>
                            <el-input v-model="form.queryContent" prefix-icon="el-icon-search" placeholder="网格名称">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onGridSubmit">查询</el-button>
                        </el-form-item>
                        <div class="item-table" style="height:61vh">
                            <div class="item-group" v-for="(item, key) in gridData" :key="key">
                                <div class="business-icon">
                                    <i class="iconfont iconwangge icon"></i>
                                </div>
                                <div class="business-test">
                                    <div class="test-name">
                                        <span class="name" style="padding: 0 5px;">{{ item.gridName }}</span>
                                        <el-tooltip class="item" effect="dark" content="实时位置" placement="top">
                                            <i class="iconfont iconshishiweizhi"
                                             @click="onGridLocation(item)"
                                                style="padding: 0 5px;color: #1ABC9C;"></i>
                                        </el-tooltip>
                                        <div class="test-a">
                                            <span class="span" @click="onDetails(item.gridId,item.scheduleList)">排班人数：<span
                                                    style="color: #FF8C1A">{{ item.scheduleList.length }}</span> 人</span>
                                            <span class="span" @click="onDetails(item.gridId,item.onDutyList)">在岗人数：<span
                                                    style="color: #FF8C1A">{{ item.onDutyList.length }}</span> 人</span>
                                        </div>
                                        <div class="test-a">
                                            <span class="span" @click="onDetails(item.gridId,item.offDutyList)">脱岗人数：<span
                                                    style="color: #FF8C1A">{{ item.offDutyList.length }}</span> 人</span>
                                            <span class="span" @click="onDetails(item.gridId,item.abnormalDutyList)">异常在岗：<span
                                                    style="color: #FF8C1A">{{ item.abnormalDutyList.length }}</span> 人</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form>

                    <!-- 人员表格 -->
                    <el-form :inline="true" :model="form" class="demo-form-inline" v-if="btnColor === '人员'">
                        <el-form-item label="网格名称" prop="roadType">
                            <el-select v-model="form.gridIdItem" placeholder="请选择" @change="getDistinctRouteChange">
                                <el-option v-for="(item, idx) in getDistinctRouteData" :key="idx" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="排班时间">
                            <el-select v-model="form.shiftIdItem" placeholder="请选择">
                                <el-option v-for="(item, idx) in schedulingTimeData" :key="idx" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onNewPersonnelSubmit">查询</el-button>
                        </el-form-item>
                        <div class="table-btn">
                            <div>
                                <el-button class="btn total" @click="tableBtnClick('总数')">总数：{{ totalPersonCount }}
                                </el-button>
                            </div>
                            <el-button class="btn" @click="tableBtnClick('排班人员')">排班人员：{{ shiftPersonCount }}
                            </el-button>
                            <el-button class="btn" @click="tableBtnClick('排班在岗')">排班在岗：{{ shiftLinePersonCount }}
                            </el-button>
                            <el-button class="btn" @click="tableBtnClick('排班脱岗')">排班脱岗：{{ shiftOffPersonCount }}
                            </el-button>
                        </div>
                        <div class="item-table" style="height: 41vh;">
                            <div class="item-group" v-for="(item, key) in newpersonnelData" :key="key">
                                <div class="business-icon">
                                    <i class="el-icon-s-custom icon" v-if="item.personImageUrlLink === null"></i>
                                    <img :src="item.personImageUrlLink" alt="" style="width: 64px;height: 64px;border-radius: 50%;" v-else>
                                </div>
                                <div class="business-test-pro">
                                    <div class="test-name">
                                        <span class="name" style="padding: 0 5px;">{{ item.name }}</span>
                                        <el-tooltip class="item" effect="dark" :content="item.sexDes"
                                            placement="top-end">
                                            <i class="iconfont"
                                                :class="item.sexDes === '男' ? 'iconxingbie-nan' : 'iconxingbie-nv'"
                                                :style="item.sexDes === '男' ? 'color: #409EFF' : 'color: #F56C6C'"
                                                style="padding: 0 5px; "></i>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" :content="item.jobStatusDes" placement="top">
                                            <i class="iconfont iconrenyuanzaigang1" style="padding: 0 5px;"
                                                :style="item.jobStatusDes ==='正常在岗' ? 'color: #1ABC9C' : item.jobStatusDes ==='异常在岗' ? 'color: #FF8C1A' : 'color: #AAADB3'"></i>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="实时位置" placement="top-start">
                                            <i class="iconfont iconshishiweizhi" style="padding: 0 5px;" @click="onPersonnelLocation(item)"></i>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="轨迹回放" placement="top-start">
                                            <i class="iconfont iconiconfonttubiao_shipinhuifang" @click="onPlayback(item)"
                                                style="padding: 0 5px;"></i>
                                        </el-tooltip>
                                    </div>
                                    <div class="test-name">
                                        <span class="text">人员类型：</span>
                                        <span class="text">{{ item.personTypeDes }}</span>
                                    </div>
                                    <div class="test-name">
                                        <span class="text">排班时间：</span>
                                        <span class="text">{{ item.shiftTime }}</span>
                                    </div>
                                    <div class="test-name">
                                        <span class="text">联系电话：</span>
                                        <span class="text">{{ item.phone }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form>

                    <!-- 车辆表格 -->
                    <el-form :inline="true" :model="form" class="demo-form-inline" v-if="btnColor === '车辆'">
                        <el-form-item label="线路名称">
                            <el-input v-model="form.routeName" placeholder="请输入线路名称"></el-input>
                        </el-form-item>
                        <el-form-item label="车辆类型" >
                            <el-select v-model="form.carType" placeholder="请选择" filterable>
                                <el-option v-for="(item, idx) in carTypeData" :key="idx" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onNewCarSubmit">查询</el-button>
                        </el-form-item>
                        <div class="table-btn">
                            <div>
                                <el-button class="btn total" @click="tableBtnClick('总数')">总数：{{peopleNum.totalCarCount}}
                                </el-button>
                            </div>
                            <el-button class="btn" @click="tableBtnClick('配备定位')">配备定位：{{ peopleNum.deviceCarCount }}
                            </el-button>
                            <el-button class="btn" @click="tableBtnClick('排班车辆')">排班车辆：{{ peopleNum.routeCarCount }}
                            </el-button>
                            <el-button class="btn" @click="tableBtnClick('在线车辆')">在线车辆：{{ peopleNum.onLineCarCount }}
                            </el-button>
                        </div>
                        <div class="item-table" style="height: 41vh;">
                            <div class="item-group" v-for="(item, key) in peopleNum.data" :key="key">
                                <div class="business-icon">
                                    <i class="iconfont iconcheliangzuoye3 icon" style="font-size: 24px" v-if="item.carImageUrl === null"></i>
                                    <img :src="item.carImageUrl" alt="" style="width: 64px;height: 64px;border-radius: 50%;" v-else>
                                </div>
                                <div class="business-test-car">
                                    <div class="test-name">
                                        <span class="name" style="padding: 0 5px;">{{ item.plateNumber }}</span>
                                        <el-tooltip class="item" effect="dark" :content="item.status" placement="top">
                                            <i class="iconfont iconcheliangzuoye3" style="padding: 0 5px;"
                                                :style="item.status ==='有效作业中' ? 'color: #1ABC9C' : item.status ==='非作业中' ? 'color: #FF8C1A' : item.status ==='报警' ? 'color: #F0090A' : 'color: #AAADB3'"></i>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="实时位置" placement="top-start">
                                            <i class="iconfont iconshishiweizhi" style="padding: 0 5px;" @click="onCarLocation(item,true)"></i>
                                        </el-tooltip>
                                    </div>
                                    <div class="test-name">
                                        <span class="text">车辆类型：</span>
                                        <span class="text">{{ item.carTypeDes }}</span>
                                    </div>
                                    <div class="test-name">
                                        <span class="text">车辆状态：</span>
                                        <span class="text">{{ item.status }}</span>
                                    </div>
                                    <div class="test-name">
                                        <span class="text">作业路线：</span>
                                        <el-tooltip effect="dark" :content="item.routeName" placement="top">
                                            <span class="text">{{ item.routeName }}</span>
                                        </el-tooltip>
                                    </div>
                                    <div class="test-name">
                                        <span class="text">所属企业：</span>
                                        <span class="text">{{ item.company }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form>

                    <!-- 作业路线 -->
                    <el-form :inline="true" :model="form" class="demo-form-inline" v-if="btnColor === '作业路线'">
                        <el-form-item label="线路名称">
                            <el-input v-model="form.routeQueryContent" placeholder="请输入线路名称"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onRouteSubmit">查询</el-button>
                        </el-form-item>
                        <!-- <el-form-item label="机扫作业情况">
                            <el-select v-model="form.sweeper" placeholder="请选择" filterable>
                                <el-option v-for="(item, idx) in $enums_hw.roadType.list" :key="idx" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="机洗作业情况">
                            <el-select v-model="form.sprinkler" placeholder="请选择" filterable>
                                <el-option v-for="(item, idx) in $enums_hw.roadType.list" :key="idx" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item> -->
                        <div class="summary">
                            <div>作业线总数：{{ jobRouteData.totalCount }} 条；总长度：{{ jobRouteData.totalRouteLenMillage }} km</div>
                            <div class="summary-sweep">
                                <p>已机扫</p>
                                <p class="sweep-color">{{ jobRouteData.todayEffectiveSweeperMillage }}km</p>
                                <p>应机扫{{ jobRouteData.totalSweeperMillage }}km</p>
                            </div>
                            <div class="hr"></div>
                            <div class="summary-sweep">
                                <p>已洒水</p>
                                <p class="sweep-color">{{ jobRouteData.todayEffectiveSprinklerMillage}}km</p>
                                <p>应洒水{{ jobRouteData.totalSprinklerMillage }}km</p>
                            </div>
                        </div>
                        <div class="item-table" style="height: 47vh">
                            <div class="item-group" v-for="(item, index) in jobRouteData.data" :key="index">
                                <div class="group-main">
                                    <span class="name" style="padding: 0 5px;">{{ item.routeName }}</span>
                                    <el-tooltip class="item" effect="dark" content="实时位置" placement="top">
                                        <i class="iconfont iconshishiweizhi" style="padding: 0 5px;" @click="onRouteLocation(item)"></i>
                                    </el-tooltip>
                                </div>
                                <div class="group-chief">
                                    <p>作业线总长：<span class="chief-color">{{ item.routeLen }}</span>km</p>
                                    <div>
                                        <span>机扫完成率：</span>
                                        <el-progress :percentage="percentageFloat(item.todaySweeperPercent)" :format="format"
                                            class="progress" :color="customColor"></el-progress>
                                    </div>
                                    <div>
                                        <span>冲洗完成率：</span>
                                        <el-progress :percentage="percentageFloat(item.todaySprinklerPercent)" :format="format"
                                            class="progress" :color="customColor"></el-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form>

                    <!-- 其它信息 -->
                    <div class="other-information" v-if="btnColor === '其它信息'">
                        <div class="other-information-area">
                            <p>标段区域</p>
                        </div>
                        <el-collapse accordion>
                            <!-- 市政道路 -->
                            <el-collapse-item v-for="(item, index) in areaItemData" :key="index">
                                <template slot="title">
                                    <div style="color:#1ABC9C;margin-left: 10px;width: 100%;margin-right: -20px;" @click="getInformationList(item.number,0)">
                                        {{ item.label }}（{{ allOtherList[item.value] }}）</div>
                                </template>
                                <div class="area-item" v-for="(items, idx) in areaAllItemData1" :key="idx">
                                    <span>{{ items.itemName }} </span>
                                    <el-tooltip class="item" effect="dark" content="实时位置" placement="top-start">
                                        <i class="iconfont iconshishiweizhi" style="padding: 0 5px;" @click="onInformationLocation(items,item.number)"></i>
                                    </el-tooltip>
                                    <div>养护企业：{{ items.maintainUnit }} </div>
                                    <div class="item-hr"></div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        <div class="other-information-area mt20px">
                            <p>标段设施</p>
                        </div>
                        <el-collapse accordion>
                            <!-- 标段设施 -->
                            <el-collapse-item v-for="(item, index) in facilitiesItemData" :key="index">
                                <template slot="title">
                                    <div style="color:#1ABC9C;margin-left: 10px;width: 100%;margin-right: -20px;" @click="getInformationList(item.number,1)">
                                        {{ item.label }}（{{ allOtherList[item.value] }}）</div>
                                </template>
                                <div class="area-item" v-for="(items, idx) in areaAllItemData2" :key="idx">
                                    <div class="area-images mr15px">
                                        <div class="area-icon" v-if="!items.hasImage">
                                            <i class="iconfont iconzhongzhuanzhan-xianxing icon"></i>
                                        </div>
                                        <div class="area-images" v-else>
                                            <!-- <ayl-image v-model="items.imageUrl" /> -->
                                            <img :src="items.imageUrl" alt="" style="width: 36px; height: 36px; border-radius: 50%;">
                                        </div>
                                    </div>
                                    <div class="area-images">
                                        <span>{{ items.itemName }} </span>
                                        <el-tooltip class="item" effect="dark" content="实时位置" placement="top-start">
                                            <i class="iconfont iconshishiweizhi" style="padding: 0 5px;" @click="onInformationLocation(items,item.number)"></i>
                                        </el-tooltip>
                                        <div>养护企业：{{ items.maintainUnit }} </div>
                                    </div>
                                    <div class="item-hr"></div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>

            <div class="police">
                <span>
                    <img src="@img/baojing.gif" alt="" style="width: 20px; height: 20px;vertical-align: bottom">
                </span>
                <div style="display: inline-block; vertical-align: bottom;">
                    <div class="tri_left"></div>
                    <span class="police-test ml10px">今日报警 {{ totalNumber }}</span>
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            let vm = this
            return {
                nav: [{
                    name: '实时监管'
                }],
                markerIcon1: require("../../../assets/images/hw-icon/bz_ljzzz.png"),
                markerIcon2: require("../../../assets/images/hw-icon/cheliang1.png"),
                markerIcon3: require("../../../assets/images/hw-icon/cheliang2.png"),
                markerIcon4: require("../../../assets/images/hw-icon/cheliang4.png"),
                markerIcon5: require("../../../assets/images/hw-icon/cheliang3.png"),
                markerIcon6: require("../../../assets/images/hw-icon/bz_gc.png"),
                markerIcon7: require("../../../assets/images/hw-icon/bz_ljhbw.png"),
                markerIcon8: require("../../../assets/images/hw-icon/renyuanyichang.png"),
                markerIcon9: require("../../../assets/images/hw-icon/renyuanlixian.png"),
                markerIcon10: require("../../../assets/images/hw-icon/renyuanzaixian.png"),
                markerIcon11: require("../../../assets/images/hw-icon/bz_ljsjd.png"),

                mapData: {//地图数据
                    polygonLnglat: null,    //点击多边形内容获取的坐标点，数组，形如[118,36]
                    strokeColor: '#1ABC9C',     //多边形线颜色
                    fillColor: '#D4EDE8',       //多边形线填充色
                    lnglat: null,        //经纬度，数组，形如[118,36]
                    markerData: [
                        // {
                        //     icon: require("../../../assets/images/hw-icon/bz_ljhbw.png"),
                        //     title: '测试1',
                        //     position: [112.92984605788085, 28.17821333691989]
                        // },
                        // {
                        //     icon: require("../../../assets/images/hw-icon/bz_ljhbw.png"),
                        //     title: '测试2',
                        //     position: [112.93731717480136, 28.150986669388107]
                        // },
                    ],      //多个点集合，包括点名称、点图标、点坐标
                    scope: [],           //标段范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
                    isShow: true,
                    scope1: [],             //两个多边形
                    polygonData: [],        //多个多边形集合
                    lineScope: null,        //单条线路
                    lineScopeData: [],       //多条线路
                    markerTitle: null,
                    markerIcon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
                }, 

                totalNumber: 0,     //今日报警总数
                tabsType: [{
                    icon: 'iconbiaoduanliebiao',
                    tabsName: '标段列表'
                }, {
                    icon: 'icontongishuoming',
                    tabsName: '统计信息'
                }, {
                    icon: 'iconshuoming',
                    tabsName: '说明'
                }],
                isClass: null,
                isShow: false,
                isDetails: false,     //点击地图控制参数
                leftTitle: null,
                rightTable: null,
                isShowRight: false,
                setChecked: null,     //上一次勾选的id
                //标段列表
                isRadio: true,
                getBidNameAndIdList: [], //树状图数据data
                defaultProps: {
                    children: 'children',
                    label: 'label',
                    disabled: function (data) {
                        if (vm.isRadio) {
                            return !!data.children
                        }
                    }
                },
                //勾选集合
                leafCheckArr: {
                    id: null,
                    label: null,
                    value: null,
                },
                oldCheckKey: '',

                //网格详情tabel
                griddingDetails: {
                    sanitationWorkGridVO: {
                        gridName: null,
                        gridTypeDes: null,
                        gridManager: null,
                        contactPhone: null,
                        gridArea: 0,
                        workStartTime: null,
                        workEndTime: null,
                        scheduleWorkerNum: 0,
                        dutyRealNum: 0,
                    },
                    sanitationShiftManageVOList: [],
                },
                

                //统计信息
                form: {
                    queryContent: null,           //网格名称
                    queryName: null,              //统计信息姓名
                    queryCar: null,               //统计信息车辆
                    gridIdItem: null,             //所属网格ID
                    shiftIdItem: null,            //排班时段id
                    routeName: null,              //线路名称
                    carType: null,                //车辆类型
                    routeQueryContent: null,      //作业路线查询
                    sweeper: null,                //机扫作业情况
                    sprinkler: null,              //机洗作业情况
                },
                //统计信息接口对象
                getStatisticsData: {},

                /**
                 * 统计信息 -- 人员信息
                 * */
                statisticalPersonnelData: [],
                /**
                 * 统计信息 -- 车辆信息
                 * */
                statisticalCarData: [],

                btnColor: null, //按钮变量
                gridData: [],

                //人员、车辆分类数量
                peopleNum: {},
                //人员列表
                newpersonnelData: [],
                personnelData: [],
                totalPersonCount: null,    //总条数
                shiftPersonCount: null,    //总条数
                shiftLinePersonCount: null,    //总条数
                shiftOffPersonCount: null,    //总条数
                getDistinctRouteData: [],     //所属网格
                schedulingTimeData: [],       //排班时间列表
                personnelDetailsData: {},     //人员详情
                

                //车辆列表
                carTypeData: [],     //车辆类型枚举
                //车辆详情列表
                carDetailsList: {},

                //作业路线
                jobRouteData: {},
                jodRouteDetailsData: {},

                //进度条颜色
                customColor: '#1ABC9C',
                
                allOtherList: {
                    cityRoadTotalNum: 0,
                    villageTotalNum: 0,
                    plotTotalNum: 0,
                    rubbishTransferTotalNum: 0,
                    rubbishCollectTotalNum: 0,
                    ecoHouseTotalNum: 0,
                    publicToiletTotalNum: 0,
                },
                //其它信息-标段区域
                areaItemData: [{
                    label: "市政道路",
                    number: 0,
                    value: "cityRoadTotalNum",
                }, {
                    label: "城中村",
                    number: 1,
                    value: "villageTotalNum",
                }, {
                    label: "国土地块",
                    number: 2,
                    value: "plotTotalNum",
                }],
                areaAllItemData: [],
                areaAllItemData1: [],
                areaAllItemData2: [],
                allPoints: [],        //所有标段区域坐标集合
                
                //其它信息-标段设施
                facilitiesItemData: [{
                    label: "垃圾中转站",
                    number: 3,
                    value: "rubbishTransferTotalNum",
                },{
                    label: "垃圾收集点",
                    number: 4,
                    value: "rubbishCollectTotalNum",
                },{
                    label: "环保垃圾屋",
                    number: 5,
                    value: "ecoHouseTotalNum",
                },{
                    label: "公共厕所",
                    number: 6,
                    value: "publicToiletTotalNum",
                },],
                //其它信息详情标识码
                informationType: null,
                otherInfoList: {},

                //初始化地图
                map: null,
                center: null,
                rechQueryMouseTool: null,
            }
        },
        methods: {
            //切换事件/打开
            clickIsShow(val) {
                this.leftTitle = val
                this.isDetails = false
                this.isShow = true
                this.$nextTick(() => {
                    this.$refs.tree.setCheckedKeys([this.setChecked])
                });
                if(val ==='统计信息') {
                    this.getStatisticsInfo()
                }
            },
            //统计信息接口方法
            async getStatisticsInfo() {
                let getStatisticsData = await this.$api_hw.realSupervise_getStatisticsInfo({})
                this.getStatisticsData = getStatisticsData
            },
            //统计信息查询人员
            onPersonnelSubmit(v) {
                this.handleCollapseChange(v,this.form.queryName)
            },
            //人员手风琴事件，查询列表
            async handleCollapseChange(flag, name) {
                let statisticalPersonnelData = await this.$api_hw.realSupervise_getPersonList({
                    queryVO: {
                        flag: flag,
                        name: name
                    }  
                })
                this.statisticalPersonnelData = statisticalPersonnelData.data
            },
            //车辆下拉事件，查询列表
            async handleCarChange(flag,plateNumber) {
                let statisticalCarData = await this.$api_hw.realSupervise_getCarList({
                    queryVO: {
                        flag: flag,
                        plateNumber: plateNumber
                    } 
                })
                this.statisticalCarData = statisticalCarData.data
            },
            onCarSubmit(v) {
                this.handleCarChange(v,this.form.queryCar)
            },
            clickCloseItem(val) {
                // this.isShow = false
                // this.isDetails = true
                //console.log(val)
            },
            //关闭左边详情页
            clockLeftDetails(val) {
                this.isDetails = false
            },
            //关闭
            clickClose() {
                this.isShow = false
                this.leftTitle = null
            },
            //关闭右边表格
            clickCloseTable() {
                this.isShowRight = false
                this.rightTable = null
                this.setChecked= null,
                this.$nextTick(() => {
                    this.$refs.tree.setCheckedKeys([])
                });
            },
            //tree charge事件单选
            async checkGroupNode(i, e) {
                if(i.value === this.leafCheckArr.value) {
                    this.leafCheckArr = {
                        id: null,
                        label: null,
                        value: null,
                    }
                    this.mapData.scope = []
                    this.mapData.scope1 = []
                    this.clickCloseTable()
                    // this.isShowRight = false
                } else {
                    this.leafCheckArr = i
                    this.isShowRight = true
                    this.btnColor = "网格"
                    this.gridList(this.form.queryContent)
                    this.isShow = false
                    // this.isDetails = true
                    this.onAllGrid()
                    this.mapData.scope1 = []
                    this.mapData.lnglat = null
                    this.rightTable = '作业监管表格'
                    let mapDataList = await this.$api_hw.commonService_getBidCoordinates({
                        bidId: i.value,
                    })
                    if(mapDataList.data !== '') {
                        this.mapData.scope = JSON.parse(mapDataList)
                    }else {
                        this.mapData.scope = []
                    }
                    
                    //this.mapData.scope1 = [[112.922677,28.169525],[112.922366,28.16786],[112.922457,28.168201],[112.922709,28.169123]]
                    //console.log(this.mapData.scope)
                }
                try {
                    if (e.checkedKeys.length > 0) {
                        this.setChecked= null
                        this.setChecked = i.id
                        this.$refs.tree.setCheckedKeys([i.id]);
                    } 
                } catch (error) {
                    
                }  
                   
            },

       
            //右边按钮事件，关闭左上列表，打开左边详情
            async tabsClick(val) {
                this.isShow = false
                this.isDetails = false
                this.btnColor = val
                this.mapData.scope1 = []
                this.mapData.lnglat = null
                this.mapData.polygonData = []
                this.mapData.lineScopeData = []
                this.mapData.markerData= []
                this.mapData.lineScope = null
                if(val === '人员') {
                    this.getPersonList(1)
                }else if(val === '车辆') {
                    this.getCarList(1)
                }else if(val === '网格') {
                    this.onAllGrid()
                    this.gridList(this.form.queryContent)
                }else if(val === '作业路线') {
                    this.onJodRoutList()
                    this.getJodRoutList()
                }else if(val === '其它信息') {
                    this.$nextTick(() =>{
                        this.onOtherInfo()
                    })
                    //this.onOtherInfo()
                    //this.getInformationList()
                }
                
            },

            //获取所选标段下所有网格数据
            async onAllGrid() {
                let allGridList = await this.$api_hw.commonService_getGridCoordinateUnderBid({
                    bidId: this.leafCheckArr.value,
                })
                
                this.mapData.polygonData = []
                this.mapData.polygonData = allGridList.map( e =>{
                    if(e.scope === null) {
                        return
                    }else {
                        return JSON.parse(e.scope)
                    }
                })
                //console.log(JSON.stringify(this.mapData.polygonData))
            },

            //右边网格列表查询
            async gridList(queryContent) {
                let gridListData = await this.$api_hw.personWorkGrid_underBidGridPersonNum({
                    bidId: this.leafCheckArr.value,
                    queryContent: queryContent
                })
                this.gridData = gridListData.data
            },
            //查询网格名称
            onGridSubmit() {
                this.gridList(this.form.queryContent)
            },
            //查看网格位置
            onGridLocation(val) {
                this.onGridDetails(val),
                this.mapData.scope1 = JSON.parse(val.gridCoordinateScope)
            },
            //查看网格详情
            async onGridDetails(val) {
                this.isDetails = true
                this.isShow = false
                this.$nextTick(() =>{
                    this.mapData.polygonData = []
                })
                
                let gridDetailsData = await this.$api_hw.personWorkGrid_getGridShiftInfo({
                    gridId: val.gridId
                })
                this.griddingDetails = gridDetailsData     
            },

            //人员列表筛选
            onNewPersonnelSubmit() {
                this.getPersonList(1,this.form.gridIdItem,this.form.shiftIdItem)
            },

            //右边查询人员列表接口
            async getPersonList(flag,gridId,shiftId) {
                this.getDistinctRouteData = []
                this.getDistinctRouteData = await this.$api_hw.common_getDistinctGrid({});
                let allPersonnelData = await this.$api_hw.realSupervise_getUnderBidPersonnelScheduling({
                    queryVO: {
                        bidId: this.leafCheckArr.value,
                        flag: flag,
                        // gridId: gridId,
                        // shiftId: shiftId,
                        gridId: this.form.gridIdItem,
                        shiftId: this.form.shiftIdItem,
                    }
                })
                this.totalPersonCount = allPersonnelData.totalPersonCount
                this.shiftPersonCount = allPersonnelData.shiftPersonCount
                this.shiftLinePersonCount = allPersonnelData.shiftLinePersonCount
                this.shiftOffPersonCount = allPersonnelData.shiftOffPersonCount

                this.personnelData = allPersonnelData.data
                this.newpersonnelData = this.personnelData
                //console.log(this.newpersonnelData)
            },
            //人员列表 -- 位置
            onPersonnelLocation(val) {
                if(val.jobStatusDes === "不在岗") {
                    this.mapData.markerIcon = this.markerIcon9
                }else if(val.jobStatusDes === "异常在岗") {
                    this.mapData.markerIcon = this.markerIcon8
                }else if(val.jobStatusDes === "正常在岗") {
                    this.mapData.markerIcon = this.markerIcon10
                }
                this.mapData.markerTitle = val.name
                this.mapData.lnglat = val.location
                this.onPersonnelDetails(val)
            },
            //人员列表 --- 通过网格查询排班时间
            async getDistinctRouteChange(val) {
                let schedulingTimeData = await this.$api_hw.realSupervise_listShiftsById({
                    //gridId: '1589857496377686497'
                    gridId: val,
                })
                this.schedulingTimeData = schedulingTimeData.map(e =>{
                    return {
                        value: e.shiftId,
                        label: e.shiftStartTime + ' ~ ' + e.shiftEndTime
                    }                 
                })
            },
            //左边列表人员详情
            async onPersonnelDetails(item) {
                this.isDetails = true
                this.isShow = false
                this.mapData.polygonData = []
                this.personnelDetailsData = {}
                let personnelDetailsData = await this.$api_hw.realSupervise_getPersonnelAdditional({
                    personId: item.personId
                })
                this.personnelDetailsData = Object.assign(item,personnelDetailsData)
                //console.log(this.personnelDetailsData)
            },

            //人员跳转到轨迹回放
            onPlayback(val) {
                if(val.deviceId !== null) {
                   this.$router.push({
                        path: "/operation-regulation/historical-playback/historical-playback",
                        query: { 
                            activeName: "trajectory",
                            objectType: 1,            //1: 人员     0 车辆
                            bidId: this.leafCheckArr.value,      //标段id
                            deviceId: val.deviceId,     //设备id
                            type: 1,
                        }
                    }); 
                } else {
                    this.$message({
                        message: '该人员未绑定设备',
                        type: 'warning'
                    });
                    return
                }
                
            },
            
            //车辆列表查询
            onNewCarSubmit() {
                this.getCarList(1,this.form.routeName,this.form.carType)
            },
            //右边查询车辆列表接口
            async getCarList(flag,routeName,carType) {
                this.peopleNum = []
                let carDataType = await this.$api_hw.common_getCodeDesList({
                    typeName: 'CarType'
                })
                this.carTypeData = carDataType.list.map(e =>{
                    return {
                        value: e.code,
                        label: e.des
                    }
                })
                let allCarData = await this.$api_hw.realSupervise_getUnderBidCarScheduling({
                    queryVO: {
                        bidId: this.leafCheckArr.value,
                        flag: flag,
                        routeName: this.form.routeName,
                        //carType: carType === "" ? null : carType,
                        carType: this.form.carType
                    }
                })
                this.peopleNum = allCarData
            },
            async onCarDetails(item,state) {
                this.isDetails = true
                this.isShow = false
                this.mapData.polygonData = []
                if(item.deviceId === void 0 || item.deviceId === null) {
                    this.$message({
                        message: '该车辆未绑定设备',
                        type: 'warning'
                    })
                     this.carDetailsList = item
                    return
                } else {
                    let carDetails = await this.$api_hw.realSupervise_getCarAdditional({
                        deviceId: item.deviceId
                    })
                    this.carDetailsList = Object.assign(item,carDetails)
                    if(state) {
                        if(item.status === "有效作业中") {
                            this.mapData.markerIcon = this.markerIcon5
                        }else if(item.status === "非作业中") {
                            this.mapData.markerIcon = this.markerIcon3
                        }else if(item.status === "报警") {
                            this.mapData.markerIcon = this.markerIcon2
                        }else if(item.status === "离线") {
                            this.mapData.markerIcon = this.markerIcon4
                        }
                        this.mapData.markerTitle = item.plateNumber
                        this.mapData.lnglat = carDetails.location
                    }
                }
                
            },

            //车辆跳转到轨迹回放
            onCarTrajectory(val) {
                if(val.deviceId !== null) {
                   this.$router.push({
                        path: "/operation-regulation/historical-playback/historical-playback",
                        query: { 
                            activeName: "trajectory",
                            objectType: 0,            //1: 人员     0 车辆
                            bidId: this.leafCheckArr.value,      //标段id
                            deviceId: val.deviceId,     //设备id
                            type: 1,
                        }
                    }); 
                } else {
                    this.$message({
                        message: '该车辆未绑定设备',
                        type: 'warning'
                    });
                    return
                }
            },

            //车辆跳转到历史回放
            onCarPlayback(val) {
                if(val.deviceId !== null) {
                   this.$router.push({
                        path: "/operation-regulation/historical-playback/historical-playback",
                        query: { 
                            activeName: "video",
                            bidId: this.leafCheckArr.value,      //标段id
                            deviceId: val.deviceId,     //设备id
                            deviceNameDes: val.deviceName,     //设备id
                            type: 1,
                        }
                    }); 
                } else {
                    this.$message({
                        message: '该车辆未绑定设备',
                        type: 'warning'
                    });
                    return
                }
            },

            //车辆跳转到多车视频
            onPlayBack(val) {
                if(val.deviceStatus === 0) {
                   this.$router.push({
                        path: "/operation-regulation/real-time-regulation/play-back",
                        query: { 
                            bidId: this.leafCheckArr.value,      //标段id
                            deviceId: val.deviceId,     //设备id
                            deviceNameDes: val.deviceName,     //设备名称
                        }
                    }); 
                } else {
                    this.$message({
                        message: '该车辆非有效作业中',
                        type: 'warning'
                    });
                    return
                }
            },
            onCarLocation(val,state) {
               this.onCarDetails(val,state) 
            },

            //人员列表
            onDetails(gridId, personIdList) {
                if(personIdList.length === 0) {
                    return
                } else {
                    this.$router.push({
                        path: '/operation-regulation/real-time-regulation/personnel-list',
                        query: {
                            gridId: gridId,
                            personIdList: personIdList,
                        }
                    });
                }
                
            },

            //人员按钮事件
            tableBtnClick(val) {
                if (val === '总数') {
                    this.getPersonList(0)
                    this.getCarList(0)
                } else if (val === '排班人员') {
                    this.getPersonList(1)
                } else if (val === '排班在岗') {
                    this.getPersonList(2)
                } else if (val === '排班脱岗') {
                    this.getPersonList(3)
                } else if (val === '配备定位') {
                    this.getCarList(3)
                } else if (val === '排班车辆') {
                    this.getCarList(1)
                } else if (val === '在线车辆') {
                    this.getCarList(2)
                }
            },

            //作业路线，获取标段下的作业线路列表 机扫、冲洗统计
            async getJodRoutList(queryContent,sprinkler,sweeper) {
                let underData = await this.$api_hw.carRoute_underBidRouteStatistical({
                    bidId: this.leafCheckArr.value,
                    sprinkler: sprinkler,
                    sweeper: sweeper,
                    queryContent: queryContent,
                })
                this.jobRouteData = underData
            },

            //获取标段下所有作业路线
            async onJodRoutList() {
                let allJodRoutList = await this.$api_hw.commonService_getRouteCoordinateUnderBid({
                    bidId: this.leafCheckArr.value,
                })
                this.mapData.lineScopeData = []
                this.mapData.lineScopeData = allJodRoutList.map( e =>{
                    if(e.scope === null) {
                        return
                    }else {
                        return JSON.parse(e.scope)
                    }
                })
                //console.log(this.mapData.lineScopeData)
            },
            //进度条转换
            percentageFloat(i) {
                let parse = parseFloat(i)
                return parse
            },
            //作业路线查询
            onRouteSubmit() {
                this.getJodRoutList(this.form.routeQueryContent)
            },
            //作业路线坐标点
            onRouteLocation(val) {
                this.onJodRouteDetails(val)
                this.mapData.lineScope = JSON.parse(val.routeCoordinateScope)
            },
            //作业路线详情
            async onJodRouteDetails(val) {
                this.isDetails = true
                this.isShow = false
                this.mapData.lineScopeData = []

                let jodRouteDetailsData = await this.$api_hw.carRoute_getRouteStatisticalInfo({
                    routeId: val.routeId,
                    todaySprinklerPercent: val.todaySprinklerPercent,
                    todaySweeperPercent: val.todaySweeperPercent,
                })
                this.jodRouteDetailsData = jodRouteDetailsData
            },
            //隔离带枚举
            hasIsolationType(type) {
                switch (type){
                    case "0":
                        return '有隔离带'
                    case "1":
                        return '无隔离带'
                }
            },
            //清扫车跳转到轨迹回放
            onSweeperTrajectory(val) {
                if(val.dutySweeperDeviceId !== null) {
                   this.$router.push({
                        path: "/operation-regulation/historical-playback/historical-playback",
                        query: { 
                            activeName: "trajectory",
                            objectType: 0,            //1: 人员     0 车辆
                            bidId: this.leafCheckArr.value,      //标段id
                            deviceId: val.dutySweeperDeviceId,     //设备id
                            type: 1,
                        }
                    }); 
                } else {
                    this.$message({
                        message: '该清扫车未绑定设备',
                        type: 'warning'
                    });
                    return
                }
            },

            //清扫车跳转到历史回放
            onSweeperPlayback(val) {
                if(val.dutySweeperDeviceId !== null) {
                   this.$router.push({
                        path: "/operation-regulation/historical-playback/historical-playback",
                        query: { 
                            activeName: "video",
                            bidId: this.leafCheckArr.value,      //标段id
                            deviceId: val.dutySweeperDeviceId,     //设备id
                            deviceNameDes: val.dutySweeperDeviceName,     //设备id
                            type: 1,
                        }
                    }); 
                } else {
                    this.$message({
                        message: '该清扫车未绑定设备',
                        type: 'warning'
                    });
                    return
                }
            },

            //清洗车跳转到轨迹回放
            onSprinklerTrajectory(val) {
                if(val.dutySprinklerDeviceId !== null) {
                   this.$router.push({
                        path: "/operation-regulation/historical-playback/historical-playback",
                        query: { 
                            activeName: "trajectory",
                            objectType: 0,            //1: 人员     0 车辆
                            bidId: this.leafCheckArr.value,      //标段id
                            deviceId: val.dutySprinklerDeviceId,     //设备id
                            type: 1,
                        }
                    }); 
                } else {
                    this.$message({
                        message: '该清洗车未绑定设备',
                        type: 'warning'
                    });
                    return
                }
            },
            //清洗车跳转到历史回放
            onSprinklerPlayback(val) {
                if(val.dutySprinklerDeviceId !== null) {
                   this.$router.push({
                        path: "/operation-regulation/historical-playback/historical-playback",
                        query: { 
                            activeName: "video",
                            bidId: this.leafCheckArr.value,      //标段id
                            deviceId: val.dutySprinklerDeviceId,     //设备id
                            deviceNameDes: val.dutySprinklerDeviceName,     //设备id
                            type: 1,
                        }
                    }); 
                } else {
                    this.$message({
                        message: '该清洗车未绑定设备',
                        type: 'warning'
                    });
                    return
                }
            },

            

            //其它信息 -- 根据分页条件和所需要查询的业务实体类型进行查询
            async getInformationList(queryType,index) {
                this.mapData.polygonData = []
                this.mapData.markerData= []
                 this.mapData.scope1 = []
                 this.mapData.lnglat = null,
                this.allPoints = []

                if(queryType === 0 || queryType === 1 || queryType === 2) {
                    let otherTypeData = await this.$api_hw.realSupervise_otherInfo_page({
                        id: this.leafCheckArr.value,
                        currentPage: 1,
                        pageSize: 2147483647,
                        queryType: queryType
                    })
                    // console.log(otherTypeData)
                    this.areaAllItemData1 = otherTypeData.data
                    if( index === 0) {
                        for(let i = 0; i< this.areaAllItemData1.length; i++) {
                            this.allPoints.push(this.areaAllItemData1[i].coordinates) 
                        }
                        this.mapData.polygonData = this.allPoints
                    }else {
                        //console.log(this.areaAllItemData1)
                        let iconItem = null
                        if(queryType === 3) {   //垃圾中转站
                            iconItem = this.markerIcon1
                        }else if(queryType === 4) {    //垃圾收集点
                            iconItem = this.markerIcon11
                        }else if(queryType === 5) {     //环保垃圾屋
                            iconItem = this.markerIcon7
                        }else if(queryType === 6) {     //公共厕所
                            iconItem = this.markerIcon6
                        }
                        for(let i = 0; i< this.areaAllItemData1.length; i++) {
                        let coordinatesList = {
                            position: this.areaAllItemData1[i].coordinates,
                            title: this.areaAllItemData1[i].itemName,
                            icon: iconItem
                        }
                            this.mapData.markerData.push(coordinatesList)
                        }
                        //console.log(this.mapData.markerData)
                    }   
                }else {
                    let otherTypeData = await this.$api_hw.realSupervise_otherInfo_page({
                        id: this.leafCheckArr.value,
                        currentPage: 1,
                        pageSize: 2147483647,
                        queryType: queryType
                    })
                    // console.log(otherTypeData)
                    this.areaAllItemData2 = otherTypeData.data
                    if( index === 0) {
                        for(let i = 0; i< this.areaAllItemData2.length; i++) {
                            this.allPoints.push(this.areaAllItemData2[i].coordinates) 
                        }
                        this.mapData.polygonData = this.allPoints
                    }else {
                        //console.log(this.areaAllItemData2)
                        let iconItem = null
                        if(queryType === 3) {   //垃圾中转站
                            iconItem = this.markerIcon1
                        }else if(queryType === 4) {    //垃圾收集点
                            iconItem = this.markerIcon11
                        }else if(queryType === 5) {     //环保垃圾屋
                            iconItem = this.markerIcon7
                        }else if(queryType === 6) {     //公共厕所
                            iconItem = this.markerIcon6
                        }
                        for(let i = 0; i< this.areaAllItemData2.length; i++) {
                        let coordinatesList = {
                            position: this.areaAllItemData2[i].coordinates,
                            title: this.areaAllItemData2[i].itemName,
                            icon: iconItem
                        }
                            this.mapData.markerData.push(coordinatesList)
                        }
                        //console.log(this.mapData.markerData)
                    }
                }
                    
               // console.log(JSON.stringify(this.allPoints))
            },

            //其他信息数量
            async onOtherInfo() {
                let otherList = await this.$api_hw.realSupervise_otherInfo_list({
                    id: this.leafCheckArr.value,
                })
                this.allOtherList = otherList
                // for(let i in otherList ) {
                //     for(let j = 0; j< this.areaItemData.length; j++) {
                //         if(this.areaItemData[j].number == i) {
                //             this.areaItemData[j].number = otherList[i]
                //         }
                //     }
                    
                //     for(let s = 0; s< this.facilitiesItemData.length; s++) {
                //         if(this.facilitiesItemData[s].number == i) {
                //             this.facilitiesItemData[s].number = otherList[i]
                //         }
                //     }
                // }
                //  this.areaItemData = this.$_.cthis.areaItemData
                //  this.facilitiesItemData = this.facilitiesItemData
            },

            //其它信息 - 查看实时位置
            onInformationLocation(val,type) {
                this.mapData.polygonData = []
                this.mapData.markerData= []
                this.mapData.scope1 = []
                this.onInformationDetails(type,val.id)
                if(val.hasLocation) {
                    if(val.geometyType === 0) {
                        if(type === 3) {   //垃圾中转站
                            this.mapData.markerIcon = this.markerIcon1
                        }else if(type === 4) {    //垃圾收集点
                            this.mapData.markerIcon = this.markerIcon11
                        }else if(type === 5) {     //环保垃圾屋
                            this.mapData.markerIcon = this.markerIcon7
                        }else if(type === 6) {     //公共厕所
                            this.mapData.markerIcon = this.markerIcon6
                        }
                        this.mapData.markerTitle = val.itemName
                        this.$nextTick(() =>{
                            this.mapData.scope1 = []
                        })
                        // this.mapData.polygonData = []
                        // this.mapData.scope1 = []
                        this.mapData.lnglat = val.coordinates
                    } else {
                        this.mapData.lnglat = null,
                        this.mapData.scope1 = val.coordinates
                    }
                } else {
                    return
                }
                
            },
            //其它信息 -详情
            async onInformationDetails(type, id) {
                this.isDetails = true
                this.isShow = false
                this.$nextTick(() =>{
                    this.mapData.polygonData = []
                })
                this.informationType = type;
                //市政道路
                if(type === 0) {
                    let municipalRoadList = await this.$api_hw.municipalRoad_getById({
                        id: id
                    })
                    this.otherInfoList = municipalRoadList
                } else if (type === 1 || type === 2) {       //城中村    ||     国土地块

                    this.otherInfoList = await this.$api_hw.plotManagement_getById({
                        id: id
                    })
                } else if(type === 3) {            //垃圾中转站
                    this.otherInfoList = await this.$api_hw.transferStationManagement_getById({
                        id: id
                    })
                } else if(type === 4) {           //垃圾收集点
                    this.otherInfoList = await this.$api_hw.refuseCollectionPoint_getById({
                        id: id
                    })
                } else if(type === 5) {           //环保垃圾屋
                    this.otherInfoList = await this.$api_hw.greenGarbageHouse_getById({
                        id: id
                    })
                } else if(type === 6) {           //公共厕所
                    this.otherInfoList = await this.$api_hw.publicToilets_getById({
                        id: id
                    })
                }
            },

            //保洁等级枚举
            cleanLevelType(type) {
                switch (type){
                    case 0:
                        return '特级'
                    case 1:
                        return '一级'
                    case 2:
                        return '二级'
                    case 3:
                        return '三级'
                }
            },

            //进度条事件
            format(percentage) {
                return percentage === 100 ? '完成' : `${percentage}%`;
            },
        },
        async mounted() {
            this.getBidNameAndIdList = await this.$api_hw.common_getBidNameAndIdList({})
            let totalNumber = await this.$api_hw.alarmManage_getTodayAiAlarmCount({})
            this.totalNumber = totalNumber.totalNum
        }
    }
</script>
<style scoped lang="sass">
    .real-main
        width: calc(100% - 36px) 
        height: 823px
        &-tree
            width: 300px
            z-index: 1
            position: absolute
            top: 110px
            left: 210px
        
            &-tabs
                width: 100%
                height: 42px
                background: rgba(255,255,255,1)
                box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
                border-radius: 2px
                .tabs-item
                    width: 100px
                    font-size: 14px
                    color: rgba(126,129,135,1)
                    line-height: 42px
                    display: inline-block
                    text-align: center
                    cursor: pointer
                .tabs-click
                    color: #1ABC9C  
            &-item
                width: 100%
                height: 500px
                overflow-y: auto
                background: rgba(255,255,255,1)
                box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
                border-radius: 2px
                margin-top: 8px
                .tree-item-title
                    height: 42px
                    border-bottom: 1px solid rgba(232,232,232,1)
                    .item-title
                        line-height: 42px
                        font-size: 14px
                        color: rgba(46,48,51,1)
                        margin-left: 10px
                    .item-close
                        float: right
                        line-height: 42px
                        margin-right: 10px
                .tree-item-list
                    padding: 10px 10px 20px
                    .list-item
                        height: 42px
                        line-height: 42px
                        margin-left: 10px
                        border-bottom: 1px solid rgba(232,232,232,1)
                    .statistics-icon
                        color: #1ABC9C
                        font-size: 14px
                    .statistics-text
                        color: #2E3033
                        margin-left: 5px
                    
                    .demo-form-inline
                        padding-left: 10px
                    .business
                        margin: 10px 0
                        .business-icon
                            width: 36px
                            height: 36px
                            background: #DDF5F0
                            border-radius: 50%
                            text-align: center
                            display: inline-block
                            vertical-align: middle
                            .icon
                                font-size: 16px
                                line-height: 33px
                                color: #8FDECE
                        .business-test
                            display: inline-block
                            vertical-align: middle
                            .test-name
                                width: 209px
                                margin: 0px 10px
                                .name
                                    font-size: 14px
                                    color: rgba(46,48,51,1)
                                .text
                                    color: #7E8187 
                    .list-test
                        color: #2E3033
                        .label
                            color: #7E8187 
                    .business-card
                        .business-card-icon
                            width: 36px
                            height: 36px
                            background: #DDF5F0
                            border-radius: 50%
                            text-align: center
                            display: inline-block
                            vertical-align: middle
                            .icon
                                font-size: 16px
                                line-height: 33px
                                color: #8FDECE
                        .business-card-text
                            display: inline-block
                            vertical-align: middle
                            .test-name
                                width: 209px
                                margin: 0px 10px
                                .name
                                    font-size: 14px
                                    color: rgba(46,48,51,1)
                                .text
                                    color: #7E8187 
                    .icon-bottom
                        .icon-bg
                            width: 30px
                            height: 30px
                            background: #EDEDED
                            border-radius: 50%
                            text-align: center
                            padding: 7px 
                            color: #AAADB3            
                                                
        &-table
            width: 418px
            height: calc( 100vh - 120px )
            background: rgba(255,255,255,1)
            box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
            border-radius: 2px
            position: absolute
            top: 110px
            right: 10px
            z-index: 151
            .real-main-table-title
                width: 100%
                height: 42px
                border-bottom: 1px solid rgba(232,232,232,1)
                .item-title
                    line-height: 42px
                    font-size: 14px
                    color: rgba(46,48,51,1)
                    margin-left: 10px
                .item-close
                    float: right
                    line-height: 42px
                    margin-right: 10px
            .real-main-table-tabs
                width: 100%
                padding: 20px 10px
                .item-button
                    margin-right: 10px
                    display: inline-block
                    vertical-align: middle
                .demo-form-inline
                    margin: 20px 0
                .table-btn
                    .btn
                        margin: 10px
                .item-table
                    height: 633px
                    overflow-y: auto
                    .item-group
                        height: 129px
                        margin: 5px 0
                        background: rgba(255,255,255,1)
                        box-shadow: 0px 4px 10px 1px rgba(4,60,41,0.1)
                        border-radius: 6px
                        .business-icon
                            width: 64px
                            height: 64px
                            background: #DDF5F0
                            border-radius: 50%
                            text-align: center
                            display: inline-block
                            vertical-align: middle
                            margin: 32px 15px
                            .icon
                                font-size: 36px
                                line-height: 64px
                                color: #8FDECE
                        .business-test
                            display: inline-block
                            vertical-align: middle
                            .test-name
                                //width: 209px
                                margin: 0px 10px
                                .name
                                    font-size: 14px
                                    color: rgba(46,48,51,1)
                                .text
                                    color: #7E8187
                            .test-a
                                width: 100%
                                padding: 15px 5px 0
                                .span
                                    cursor: pointer
                                    margin-right: 20px
                                    text-decoration: underline
                        .business-test-pro
                            display: inline-block
                            vertical-align: middle
                            .test-name
                                width: 209px
                                margin: 5px 10px
                                .name
                                    font-size: 14px
                                    color: rgba(46,48,51,1)
                                .text
                                    color: #7E8187 
                        .business-test-car
                            display: inline-block
                            vertical-align: middle
                            .test-name
                                width: 270px
                                overflow: hidden
                                white-space: nowrap
                                text-overflow: ellipsis
                                margin: 5px 10px
                                .name
                                    font-size: 14px
                                    color: rgba(46,48,51,1)
                                .text
                                    color: #7E8187 
                .summary
                    width: 100%
                    .hr
                        width: 1px
                        height: 54px
                        background: rgba(232,232,232,1)
                        display: inline-block
                        vertical-align: middle 
                        margin-top: 15px
                    .summary-sweep
                        margin-top: 15px
                        width: calc(50% - 1px)
                        display: inline-block
                        vertical-align: middle
                        text-align: center 
                        line-height: 24px  
                        color: rgba(170,173,179,1)
                        .sweep-color
                            color: #FF8C1A
                .item-table
                    height: 41vh
                    overflow-y: auto
                    .item-group
                        height: 129px
                        margin: 5px 0
                        background: rgba(255,255,255,1)
                        box-shadow: 0px 4px 10px 1px rgba(4,60,41,0.1)
                        border-radius: 6px
                        .group-main
                            padding: 10px
                            .name
                                font-size: 14px
                                color: rgba(46,48,51,1)
                        .group-chief
                            padding: 0 15px
                            .chief-color
                                color: #FF8C1A
                            .progress
                                width: 60%
                                margin: 10px 0 0
                                display: inline-block
                    
                .other-information
                    margin: 20px 0
                    height: 67vh
                    overflow-y: auto
                    .other-information-area
                        color: rgba(46,48,51,1)
                        padding-left: 5px
                        border-left: 2px solid #1ABC9C
                        margin-bottom: 10px
                    .area-item
                        margin: 0 0 5px 15px
                        color: #7E8187
                        .item-hr
                            height: 1px
                            width: 100%
                            background: #EEEEEE
                        .area-images
                            display: inline-block
                            vertical-align: middle
                            .area-icon
                                width: 36px
                                height: 36px
                                background: #DDF5F0
                                border-radius: 50%
                                text-align: center
                                .icon
                                    font-size: 28px
                                    line-height: 36px
                                    color: #8FDECE
                            .area-images
                                width: 36px
                                height: 36px
                                border-radius: 50%
                                img
                                    width: 36px
                                    height: 36px
                                    border-radius: 50%
        .police
            width: 300px
            z-index: 1
            position: absolute
            bottom: 20px
            left: 215px
            .tri_left:before
                content: ""
                width: 0px
                height: 0px
                border-top: 5px solid transparent
                border-bottom: 5px solid transparent
                border-right: 5px solid #F2090A
                position: absolute
                top: 8px
                left: 25px
            .police-test
                color: #fff
                font-size: 12px
                background-color: #F2090A
                padding: 5px
                border-radius: 2px                            
    /deep/ .el-button--primary 
        color: #FFFFFF
        background-color: #1ABC9C
        border-color: #1ABC9C
    /deep/.el-input--small .el-input__inner 
        width: 180px
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner
        background-color: #1ABC9C
        border-color: #1ABC9C
    /deep/.el-checkbox__inner:hover 
        border-color: #1ABC9C
</style>